<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Produto <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.idpip_produto"
            :options="form_options.produtos"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Discriminação <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.discriminacao"
            placeholder="Digite a discriminação"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.tipo"
            :options="form_options.tipo"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Unidade de medida <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.nome_unidade_medida"
            placeholder="Digite a unidade de medida"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Quantidade <span class="text-danger">*</span>
        </template>
        <template #default>
          <KTInputNumeric
            v-model="form.quantidade"
            :precision="2"
            placeholder="Digite a quantidade"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label>
          Preço unitário <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-input-group size="lg" :prepend="simbolo_moeda">
            <KTInputNumeric
              v-model="form.preco_unitario"
              :precision="2"
              placeholder="Digite o preço unitário"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import CommonDataService from "@/core/services/commondata.service";

export default {
  name: "PipInsumoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto_insumo: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-shopping-cart",
        title: "Materiais",
        description: `Selecione o produto e preencha todos os campos. Mais detalhes na Ajuda (?).`
      },
      form: {},
      form_options: {
        produtos: [],
        tipo: [
          { text: "Insumo", value: 1 },
          { text: "Material de embalagem", value: 2 }
        ]
      },
      simbolo_moeda: "",
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/insumo`;
    },
    manual() {
      return {
        session: "insumo",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_produto_insumo;
    },
    parentRoute() {
      return {
        name: "pip_insumo_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [
        this.Api.GetData(this.idpip_produto_insumo),
        CommonDataService.getProduto(this.idplano_investimento)
      ];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_PRODUTO = 1;

        this.form = res[R_GETDATA].insumo ?? {};
        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;
        this.form_options.produtos = res[R_PRODUTO];
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_produto_insumo, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
